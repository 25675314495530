import React from "react"

import { FaLinkedin } from 'react-icons/fa';

const navigation = {
    services: [
        { name: 'AktiverHAN', href: '/tjenester/aktiverhan' },
        { name: 'ELHub Søk', href: '/tjenester/elhubsok' },
        { name: 'Konsulent', href: '/tjenester/konsulent' },
    ],
    kinetik: [
        { name: 'Nett', href: '/kinetik/nett' },
        { name: 'Kraft', href: '/kinetik/kraft' },
    ],
    company: [
        { name: 'Historie', href: '/om/' },
        { name: 'Kontakt', href: '/om/#kontakt' },
        { name: 'Jobb', href: '/om/#jobb' },
    ],
    social: [
        {
            name: 'LinkedIn',
            href: 'https://no.linkedin.com/company/convivialas',
            icon: FaLinkedin
        }
    ],
};

// @ts-ignore
import ConvivialLogo from '../../../static/convivial-logo.svg';

interface IFooter {
    bgColor?: string;
}

export default function Footer(props: IFooter) {
    return (
        <footer className={props.bgColor ? props.bgColor : "bg-white"} aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8 xl:col-span-1">
                        <img
                            className="h-6"
                            src={ConvivialLogo}
                            alt="Convivial"
                        />
                        <p className="text-gray-500 text-base">
                            Vi hjelper morgendagens vinnere finne bortgjemte&nbsp;data.

                            <div className="mt-2 text-gray-400 text-sm">
                                <div>Muusøya 1</div>
                                <div>3023 Drammen</div>
                                <div>Org. nr.: 923 137 572</div>
                            </div>

                        </p>
                        <div className="flex space-x-6">
                            {navigation.social.map((item) => (
                                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-1 xl:grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                        </div>
                        <div className="md:grid md:grid-cols-3 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Kinetik</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.kinetik.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider mt-8 md:mt-0 uppercase">Tjenester</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.services.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mt-8 md:mt-0">Convivial</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.company.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 pt-8 border-t border-gray-100">
                    <p className="text-base  text-gray-400 xl:text-center">Convivial AS</p>
                </div>
            </div>
        </footer>
    );
}
