import { Popover, Transition } from '@headlessui/react';
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react'
import React from 'react';
import * as Scroll from 'react-scroll';

const products = [
    {
        name: 'Kinetik Nett',
        description: 'Full oversikt over nettet',
        href: 'https://grid.kinetik.no',
        icon: GiElectric,
    },
    {
        name: 'Kinetik Kraft',
        description: 'Kraftverket rett i lomma',
        href: 'https://console.kinetik.no',
        icon: RiWaterFlashFill,
    },
];


const solutions = [
    {
        name: 'Kinetik Nett',
        description: 'Full oversikt over nettet',
        href: '/kinetik/nett',
        icon: GiElectric,
    },
    {
        name: 'Kinetik Kraft',
        description: 'Kraftverket rett i lomma',
        href: '/kinetik/kraft',
        icon: RiWaterFlashFill,
    },
    {
        name: 'AktiverHAN',
        description: "Automatisert HAN-port administrasjon",
        href: '/tjenester/aktiverhan',
        icon: CgEthernet
    },
    {
        name: 'ELHub søk',
        description: "Oppslag for strømsalg",
        href: '/tjenester/elhubsok',
        icon: AiOutlineSearch,
    }
];

const navigation = [
    { name: 'Prosjekter', href: '/#prosjekter' },
    { name: 'Om oss', href: '/om' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


import { AiOutlineSearch } from 'react-icons/ai';
import { CgEthernet } from 'react-icons/cg';
import { GiElectric } from 'react-icons/gi';
import { RiWaterFlashFill } from 'react-icons/ri';
import { BsArrowRightShort, BsArrowUp, BsFileArrowUp } from 'react-icons/bs';

// @ts-ignore
import convivialLogo from '../../../static/convivial-logo.svg'
import { useScrollPosition } from '@n8tb1t/use-scroll-position';


function LogonMenu() {
    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-transparent pl-2 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 md:focus:ring-convivial focus:ring-white'
                        )}
                    >
                        <span>Logg på <BsArrowRightShort className="inline text-convivial" size={24} /></span>
                    </Popover.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            static
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-auto sm:px-0 w-full md:w-auto "
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    {products.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            target="_blank"
                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                        >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-white ring-convivial ring-2 text-white sm:h-12 sm:w-12">
                                                <item.icon className="h-6 w-6 text-convivial" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}


function BackToTopButton() {
    let scroll = Scroll.animateScroll;
    return (
        <button
            type="button"
            onClick={() => scroll.scrollToTop()}
            className="position fixed right-4 bottom-4 z-50 text-gray-600 bg-white ring-gray-300 ring-2 animate-fade-in-down rounded-md hover:bg-gray-50"
        >
            <BsArrowUp size="38" />
        </button>
    )
}

interface ISiteNavigation {
    bgColor: string;
}

export default function SiteNavigation(props: ISiteNavigation) {

    const [hideOnScroll, setHideOnScroll] = useState(true)
    const [hideBackToTop, setHideBackToTop] = useState(true);

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y
        if (currPos.y > -100) {
            // hide
            if (!hideBackToTop) setHideBackToTop(true);
        } else {
            // show
            if (hideBackToTop) setHideBackToTop(false);
        }
        if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    }, [hideOnScroll])

    return (
        <header id="top">
            {hideBackToTop ? null : <BackToTopButton />}
            <Popover className={"relative " + props.bgColor}>
                {({ open }) => (
                    <>
                        <div className="flex justify-between max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                <a href="/">
                                    <span className="sr-only">Convivial</span>
                                    <img
                                        className="h-6 w-auto sm:h-5"
                                        src={convivialLogo}
                                        alt="Convivial"
                                    />
                                </a>                            </div>
                            <div className="-mr-2 -my-2 md:hidden">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-convivial">
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden md:flex space-x-10">
                                <Popover className="relative">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open ? 'text-gray-900' : 'text-gray-500',
                                                    'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-convivial'
                                                )}
                                            >
                                                <span>Tjenester</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'text-gray-600' : 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel
                                                    static
                                                    className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/3"
                                                >
                                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                            {solutions.map((item) => (
                                                                <a
                                                                    key={item.name}
                                                                    href={item.href}
                                                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                                >
                                                                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-white ring-convivial ring-2 text-white sm:h-12 sm:w-12">
                                                                        <item.icon className="h-6 w-6 text-convivial" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                                    </div>
                                                                </a>
                                                            ))}
                                                        </div>
                                                        <div className="p-5 bg-gray-100 sm:p-8">
                                                            <a
                                                                href="/tjenester/konsulent"
                                                                className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                                                            >
                                                                <span className="flex items-center">
                                                                    <span className="text-base font-medium text-gray-900">Konsulent</span>
                                                                    <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-white text-convivial">
                                                                        Joviale folk
                                                                    </span>
                                                                </span>
                                                                <span className="mt-1 block text-sm text-gray-500">
                                                                    Vi strekker oss alltid langt for at kundene våre skal lykkes.
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>

                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>

                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </Popover.Group>
                            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                <LogonMenu />
                            </div>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                static
                                className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                    <div className="pt-5 pb-6 px-5">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <img
                                                    className="h-6 w-auto"
                                                    src={convivialLogo}
                                                    alt="Convivial" />
                                            </div>
                                            <div className="-mr-2">
                                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-convivial">
                                                    <span className="sr-only">Close menu</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </Popover.Button>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <nav className="grid grid-cols-1 gap-7">
                                                {solutions.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                                    >
                                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-convivial text-white">
                                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                                                    </a>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="py-6 px-5">
                                        <div className="grid grid-cols-1 gap-4">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                        <div className="mt-6 ring-2 ring-convivial rounded-md">
                                            <p className="mt-6 text-center text-base font-medium text-gray-500 p-1">
                                                <LogonMenu />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </header>
    );
}
